


import { Radar } from 'vue-chartjs';
import Chart from 'chart.js';
import 'chartjs-adapter-moment';

let draw = Chart.controllers.radar.prototype.draw;    
Chart.controllers.radar.prototype.draw = function () {
    let chart = this.chart;
    let ctx = chart.ctx;
    let _stroke = ctx.stroke;
    ctx.stroke = function () {
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)' 
        ctx.shadowBlur = 12;
        ctx.shadowOffsetX = -4;
        ctx.shadowOffsetY = 0;
        _stroke.apply(this, arguments);
        ctx.restore();
    };
    draw.apply(this, arguments);
    ctx.stroke = _stroke;
}

export default {
    extends: Radar,
    components: {
    },
    data() {
        return {                
            data: {},
            newTimberData: [],
            newTimberTrueValue: [],
            newConcreteData: [],
            newConcreteTrueValue: [],
            newSteelData: [],
            newSteelTrueValue: [],
            options: {
                title: {
                    display: false
                },
                legend: {
                    display: true,
                    labels: {
                        color: 'rgb(255, 99, 132)',
                        fontSize: 12,
                        fontWeight: 'bold',
                        padding: 40,
                        fontFamily: 'sans-serif',
                    }
                },
                scale: {
                    pointLabels: {
                        fontSize: 14,
                        fontFamily: 'sans-serif'                            
                    },
                    ticks: {
                        display: false,
                        suggestedMin: 0,
                        suggestedMax: 5,
                        stepSize: 1,
                        callback: function (value, index, values) {
                            return value
                        }
                    },
                    gridLines: {
                        circular: true
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],                    
                tooltips: {
                    enabled: true,
                    callbacks: {                            
                        title: (tooltipItem, data) => data.labelsTooltip[tooltipItem[0].index],
                        label: function (tooltipItem, data) {                               
                            return ' ' + data.datasets[tooltipItem.datasetIndex].label + ' : ' + data.datasets[tooltipItem.datasetIndex].dataTrueValue[tooltipItem.index];                                
                        },
                        labelColor: function (tooltipItem, chart) {
                            return {
                                borderColor: 'rgb(255, 255, 255)',
                                backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor
                            }
                        }
                    }
                },
                hover: {
                    mode: 'nearest',
                    intersect: false
                },                                      
                onHover: (e, activeEls) => {

                    if (!activeEls || activeEls.length == 0) {
                        this.$root.$emit('onRadarTooltip', null);
                        return;
                    }                                                
                   
                    var tooltipactive = this.$data._chart.tooltip._active;                                 
                    if (tooltipactive && tooltipactive.length > 0) {
                        this.$root.$emit('onRadarTooltip', null);
                        return;
                    }                        

                    var myChart = this.$data._chart;
                    var ytop = myChart.chartArea.top;
                    var ybottom = myChart.chartArea.bottom;
                    var xtop = myChart.chartArea.left;
                    var xbottom = myChart.chartArea.right;

                    var maxHeight = 0, maxWidth = 0;

                    for (var i = 0; i < myChart.scale._pointLabelSizes.length; i++) {
                        if (myChart.scale._pointLabelSizes[i].h > maxHeight) {
                            maxHeight = myChart.scale._pointLabelSizes[i].h;
                        }
                        if (myChart.scale._pointLabelSizes[i].w > maxWidth) {
                            maxWidth = myChart.scale._pointLabelSizes[i].w;
                        }
                    }
                    ybottom = ybottom - maxHeight;
                    ytop = ytop + maxHeight;
                    xbottom = xbottom - maxWidth;
                    xtop = xtop + maxWidth;

                    if (e.offsetY < ybottom && e.offsetY > ytop && e.offsetX < xbottom && e.offsetX > xtop) {
                        this.$root.$emit('onRadarTooltip', null);
                        return;
                    }

                    const dataIndex = activeEls[0]._index;
                    let label = this.$data._chart.data.labels[dataIndex];

                    switch (dataIndex) {
                        case 1:
                            label = `<strong>${label.join(' ')} :</strong><br />Depth of structure in mm from top of floor to underside of structure`;                                
                            break;
                        case 2:
                            label = `<strong>${label} :</strong><br />Cost of material placed, in $/m<sup>2</sup>`;
                            break;
                        case 3:
                            label = `<strong>${label} :</strong><br />Embodied carbon of structural material in kgCO<sub>2</sub>/m<sup>2</sup>`;
                            break;
                        default:
                        label = `<strong>${label.join(' ')} :</strong><br />This is the overall weight of the structural bay in kg/m<sup>2</sup>`;                                
                    }

                    const paramTT = { text: label, x: e.pageX, y: e.pageY };
                    this.$root.$emit('onRadarTooltip', paramTT);                                                    
                                     
                },
                onClick: (e) => {
                    this.$root.$emit('MainComponent');                     
                },
                
            }
        }
    },
    created() {
        this.$root.$on('refreshChart', this.refreshChart);
        this.$root.$on('generateChart', this.generateChart);
    },
   methods: {
        generateChart() {
            this.data = {}

            // Compute datasets and formattedLabels
            let labels = [["Bay", "Weight"], ["Structural", "Depth"], "Cost", "Carbon"] //, ["Material", "Efficiency"]]
            let labelsTooltip = ["Bay Weight", "Structural Depth", "Cost", "Carbon"] //, "Material Efficiency"]
            let dataTimber = this.newTimberData;
            let dataTimberTrueValue = this.newTimberTrueValue;
            let dataConcrete = this.newConcreteData;
            let dataConcreteTrueValue = this.newConcreteTrueValue;
            let dataSteel = this.newSteelData;
            let dataSteelTrueValue = this.newSteelTrueValue;                                

            let datasets =
            [
                {
                    label: "timber",
                    data: dataTimber,
                    dataTrueValue: dataTimberTrueValue,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius:10,
                    fill: true,
                    borderColor: '#0000009B',
                    backgroundColor: '#FFC00066',
                    lineTension: 0.3,
                },
                {
                    label: "concrete",
                    data: dataConcrete,
                    dataTrueValue: dataConcreteTrueValue,
                    borderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius:10,
                    fill: true,
                    borderColor: '#9E9E9E66',
                    backgroundColor: '#9E9E9E66',
                    lineTension: 0.3,
                },
                {
                    label: "steel",
                    data: dataSteel,
                    dataTrueValue: dataSteelTrueValue,
                    borderWidth: 0,
                    pointRadius: 0,
                    pointHitRadius:10,
                    fill: true,
                    borderColor: '#4EB1C866',
                    backgroundColor: '#4EB1C866',
                    lineTension: 0.3,
                }
            ]

            this.data = {
                labels: labels,
                datasets: datasets,
                labelsTooltip: labelsTooltip
            }

            this.renderChart(this.data, this.options)               
        },
        async refreshChart(materialType) {
            try {
                if (materialType === 'Timber') {
                    await Promise.resolve(this.newTimberData = this.$store.getters['materials/getChartDataByMaterialType'](materialType));
                    await Promise.resolve(this.newTimberTrueValue = this.$store.getters['materials/getChartTrueValueByMaterialType'](materialType));
                } else if (materialType === 'Concrete') {
                    await Promise.resolve(this.newConcreteData = this.$store.getters['materials/getChartDataByMaterialType'](materialType));
                    await Promise.resolve(this.newConcreteTrueValue = this.$store.getters['materials/getChartTrueValueByMaterialType'](materialType));
                } else if (materialType === 'Steel') {
                    await Promise.resolve(this.newSteelData = this.$store.getters['materials/getChartDataByMaterialType'](materialType));
                    await Promise.resolve(this.newSteelTrueValue = this.$store.getters['materials/getChartTrueValueByMaterialType'](materialType));
                }                    
            } catch (error) {
                console.log(error);
            } finally {
                if (this.$data._chart) {
                    this.$data._chart.update()
                } else {
                    this.generateChart() 
                }
            }
        }
    }
}
